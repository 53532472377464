var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mt-4 py-2 d-flex align-center",
      attrs: { color: "primary" }
    },
    [
      _c(
        "v-tabs",
        {
          attrs: {
            "background-color": "primary",
            dark: "",
            "icons-and-text": ""
          }
        },
        [
          _c("v-tabs-slider"),
          _vm._l(_vm.tabs, function(tab, index) {
            return [
              _c(
                "v-tab",
                {
                  key: index,
                  staticClass: "ml-4 psi-account-tab",
                  attrs: { to: { name: tab.route } }
                },
                [
                  _vm._v(" " + _vm._s(tab.title) + " "),
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v(_vm._s(tab.icon))
                  ])
                ],
                1
              )
            ]
          })
        ],
        2
      ),
      _c("v-spacer"),
      _c(
        "v-tooltip",
        {
          attrs: { top: "", color: "primary darken-2" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mr-4",
                          attrs: {
                            fab: "",
                            large: "",
                            color: "primary lighten-2"
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { large: "" } }, [
                        _vm._v("mdi-account-plus")
                      ])
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v("Invite new user")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }