<template>
    <v-card class="mt-4 py-2 d-flex align-center" color="primary">
        <v-tabs background-color="primary" dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <template v-for="(tab, index) in tabs">
                <v-tab
                    class="ml-4 psi-account-tab"
                    :to="{ name: tab.route }"
                    :key="index"
                >
                    {{ tab.title }}
                    <v-icon large>{{ tab.icon }}</v-icon>
                </v-tab>
            </template>
        </v-tabs>
        <v-spacer></v-spacer>
        <v-tooltip top color="primary darken-2">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    large
                    class="mr-4"
                    color="primary lighten-2"
                    v-on="on"
                    v-bind="attrs"
                >
                    <v-icon large>mdi-account-plus</v-icon>
                </v-btn>
            </template>
            <span>Invite new user</span>
        </v-tooltip>
    </v-card>
</template>
<script>
export default {
    name: "account-tool-bar",
    components: {},
    props: {},
    data() {
        return {
            tabs: this.$config("account.accountMenuItems"),
        };
    },
};
</script>

<style scoped>
.psi-account-tab {
    width: 165px;
}
</style>